// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-examens-js": () => import("/opt/build/repo/src/pages/examens.js" /* webpackChunkName: "component---src-pages-examens-js" */),
  "component---src-pages-examens-2-todrive-js": () => import("/opt/build/repo/src/pages/examens/2todrive.js" /* webpackChunkName: "component---src-pages-examens-2-todrive-js" */),
  "component---src-pages-examens-faalangst-js": () => import("/opt/build/repo/src/pages/examens/faalangst.js" /* webpackChunkName: "component---src-pages-examens-faalangst-js" */),
  "component---src-pages-examens-praktijkexamen-js": () => import("/opt/build/repo/src/pages/examens/praktijkexamen.js" /* webpackChunkName: "component---src-pages-examens-praktijkexamen-js" */),
  "component---src-pages-examens-tussentijdse-toets-js": () => import("/opt/build/repo/src/pages/examens/tussentijdse-toets.js" /* webpackChunkName: "component---src-pages-examens-tussentijdse-toets-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inschrijvingen-js": () => import("/opt/build/repo/src/pages/inschrijvingen.js" /* webpackChunkName: "component---src-pages-inschrijvingen-js" */),
  "component---src-pages-proefles-js": () => import("/opt/build/repo/src/pages/proefles.js" /* webpackChunkName: "component---src-pages-proefles-js" */),
  "component---src-pages-tarieven-js": () => import("/opt/build/repo/src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */),
  "component---src-pages-theorie-js": () => import("/opt/build/repo/src/pages/theorie.js" /* webpackChunkName: "component---src-pages-theorie-js" */)
}

